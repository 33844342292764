import React, { useEffect } from "react";
import "animate.css";
import WOW from "wowjs";

const TermsConditions = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
      animateClass: "animate__animated",
    }).init();
  }, []);

  return (
    <>
      <div className="p-5 xl:px-14 pt-10 xl:pt-16 flex flex-col gap-5">
        <h1 className="wow animate__fadeIn text-3xl xl:text-4xl 2xl:text-6xl font-medium">
          Terms and Conditions
        </h1>
        <div className="wow animate__fadeIn lg:mt-3" data-wow-delay="0.5s">
          <p className="desc">Welcome to Spirinova! These Terms and Conditions govern your use of our website, products, and services. By accessing or using our services, you agree to comply with these terms.</p>
          <ol className="list-decimal ml-4 mt-3">
            <li>
                Services Offered
                <ul className="list-disc ml-5">
                <li>Spirinova provides software development services, code delivery is done via GitHub or other code-sharing platforms, and infrastructure setup is done on cloud platforms as required by the client.</li>
                <li>Access to the services is provided as per the agreed contract terms.</li>
                </ul>
            </li>
            <li>
                User Responsibilities
                <ul className="list-disc ml-5">
                <li>You agree to provide accurate information during the registration or purchase process.</li>
                <li>You are responsible for maintaining the confidentiality of your credentials.</li>
                <li>You agree not to misuse or reverse-engineer any software or services provided by Spirinova.</li>
                </ul>
            </li>
            <li>
                Payment Terms
                <ul className="list-disc ml-5">
                <li>Payment must be made as per the signed agreements.</li>
                <li>All transactions are securely processed through [Payment Provider/Payment Gateway].</li>
                </ul>
            </li>
            <li>
                Intellectual Property
                <ul className="list-disc ml-5">
                <li>Spirinova retains ownership of all software and intellectual property unless explicitly transferred to the client via contract.</li>
                <li>You may use the software or services solely for the purpose specified in your agreement with us.</li>
                </ul>
            </li>
            <li>
                Delivery
                <ul className="list-disc ml-5">
                <li>Code will be delivered via GitHub or similar platforms, and infrastructure will be set up on agreed-upon cloud platforms.</li>
                <li>Delivery timelines are as per the terms of the contract or service agreement.</li>
                </ul>
            </li>
            <li>
                Cancellation and Refunds
                <ul className="list-disc ml-5">
                <li>Please refer to our Cancellation and Refund Policy below.</li>
                </ul>
            </li>
            <li>
                Limitation of Liability
                <ul className="list-disc ml-5">
                <li>Spirinova is not liable for any direct, indirect, or incidental damages resulting from the use of our services.</li>
                <li>Clients are responsible for ensuring that software or infrastructure complies with their local laws and requirements.</li>
                </ul>
            </li>
            <li>
                Governing Law
                <ul className="list-disc ml-5">
                <li>These terms shall be governed and construed in accordance with the laws of India, without regard to conflict of law provisions.</li>
                </ul>
            </li>
          </ol>
          <div className="mt-3">
            <h2 className="font-semibold">Contact Information</h2>
            <p>If you have any questions, please contact us:</p>
            <ul className="list-disc ml-9">
                <li>Email: umesh@spirinova.dev</li>
                <li>Phone: +91-8605633693</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsConditions;