import React, { useEffect } from "react";
import "animate.css";
import WOW from "wowjs";

const CancellationRefund = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
      animateClass: "animate__animated",
    }).init();
  }, []);

  return (
    <>
      <div className="p-5 xl:px-14 pt-10 xl:pt-16 flex flex-col gap-5">
        <h1 className="wow animate__fadeIn text-3xl xl:text-4xl 2xl:text-6xl font-medium">
            Cancellation and Refund Policy
        </h1>
        <div className="wow animate__fadeIn lg:mt-3" data-wow-delay="0.5s">
          <p className="desc">At Spirinova, we strive to ensure your satisfaction with our services. Below are our policies for cancellations and refunds.</p>
          <ol className="list-decimal ml-4 mt-3">
          <li>
            Cancellation Policy
            <ul className="list-disc ml-5">
              <li>
                Before Service Commencement:
                <ul className="list-disc ml-5">
                  <li>Cancellations made before we begin delivering services will receive a full refund, minus any applicable transaction fees.</li>
                </ul>
              </li>
              <li>
                After Service Commencement:
                <ul className="list-disc ml-5">
                  <li>Cancellations after service delivery has started are subject to the milestones completed. Refunds will be calculated based on work completed.</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Refund Policy
            <ul className="list-disc ml-5">
              <li>
                Eligible Refunds:
                <ul className="list-disc ml-5">
                  <li>Refunds are applicable only if:</li>
                  <ul className="list-disc ml-5">
                    <li>The service was not delivered as agreed.</li>
                    <li>There are significant technical issues or failures that we are unable to resolve.</li>
                  </ul>
                  <li>Refunds are not provided for changes in customer requirements after the work has begun.</li>
                </ul>
              </li>
              <li>
                Non-Refundable Services:
                <ul className="list-disc ml-5">
                  <li>Custom software development where the code has already been delivered.</li>
                  <li>Subscription-based services or licenses once activated.</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Process for Requesting Refunds
            <ul className="list-disc ml-5">
              <li>To request a refund, contact us at umesh@spirinova.dev with your order details and a clear explanation of the issue.</li>
              <li>Refund requests will be processed within 10 business days of approval.</li>
            </ul>
          </li>
          <li>
            Dispute Resolution
            <ul className="list-disc ml-5">
              <li>In case of disputes, we encourage clients to contact us directly to resolve the matter amicably.</li>
            </ul>
          </li>
        </ol>
          <div className="mt-3">
            <h2 className="font-semibold">Contact Information</h2>
            <p>If you have any questions, please contact us:</p>
            <ul className="list-disc ml-9">
                <li>Email: umesh@spirinova.dev</li>
                <li>Phone: +91-8605633693</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancellationRefund;