import React, { useEffect } from "react";
import "animate.css";
import WOW from "wowjs";

const PrivacyPolicy = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
      animateClass: "animate__animated",
    }).init();
  }, []);

  return (
    <>
      <div className="p-5 xl:px-14 pt-10 xl:pt-16 flex flex-col gap-5">
        <h1 className="wow animate__fadeIn text-3xl xl:text-4xl 2xl:text-6xl font-medium">
            Privacy Policy
        </h1>
        <div className="wow animate__fadeIn lg:mt-3" data-wow-delay="0.5s">
          <p className="desc">At Spirinova, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information.</p>
          <ol className="list-decimal ml-4 mt-3">
            <li>
                Information We Collect
                <ul className="list-disc ml-5">
                <li>Personal Information: Name, email, phone number, and billing information provided during the registration or purchase process.</li>
                <li>Usage Data: Information about how you use our website and services, such as IP address, browser type, and pages visited.</li>
                </ul>
            </li>
            <li>
                How We Use Your Information
                <ul className="list-disc ml-5">
                <li>To provide and improve our services.</li>
                <li>To process payments and deliver software or infrastructure.</li>
                <li>To send notifications related to service updates, offers, or important announcements.</li>
                </ul>
            </li>
            <li>
                Sharing Your Information
                <ul className="list-disc ml-5">
                <li>We do not sell or rent your personal information to third parties.</li>
                <li>We may share information with trusted third-party service providers (e.g., GitHub, cloud providers) for service delivery.</li>
                </ul>
            </li>
            <li>
                Data Security
                <ul className="list-disc ml-5">
                <li>We use industry-standard security measures to protect your data.</li>
                <li>However, no method of electronic storage is 100% secure, and we cannot guarantee absolute security.</li>
                </ul>
            </li>
            <li>
                Your Rights
                <ul className="list-disc ml-5">
                <li>You have the right to access, modify, or delete your personal data.</li>
                <li>You may opt out of marketing communications at any time by contacting us at umesh@spirinova.dev.</li>
                </ul>
            </li>
            <li>
                Changes to This Policy
                <ul className="list-disc ml-5">
                <li>We may update this Privacy Policy from time to time. Changes will be posted on this page with the updated effective date.</li>
                </ul>
            </li>
          </ol>
          <div className="mt-3">
            <h2 className="font-semibold">Contact Information</h2>
            <p>If you have any questions, please contact us:</p>
            <ul className="list-disc ml-9">
                <li>Email: umesh@spirinova.dev</li>
                <li>Phone: +91-8605633693</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;