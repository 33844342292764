import React from 'react'
// import ContactUs from './ContactUs'
import plus from "../../assets/top-right-arrow.png"
import logo from "../../assets/FullLogo.png"
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa6'
import { Link, useNavigate } from 'react-router-dom';
import ContactUs from '../contact-us';


const Footer = ({email}) => {
    const navigate = useNavigate();

    const Menu = ({title, menu,link}) => (
        <Link to={link}>
        <div>
            <h1 className='text-base font-semibold'>{title}</h1>
            {menu.map((item) => (
                <h2 className='text-sm text-white/60 mt-1 cursor-pointer'>{item}</h2>
            ))}
        </div>
        </Link>
    )

    const Icon = ({ children, link }) => (
        <a href={link} target="_blank" rel="noopener noreferrer" className='text-[#282E3C] bg-[#80838A] p-2.5 rounded-full w-fit hover:bg-white'>
            {children}
        </a>
    )

  return (
    <div>
        <ContactUs />
        <div className='pt-56 xl:pt-[23vh] p-5 xl:p-10 bg-[#181D2B] text-white flex flex-col items-start gap-6 xl:gap-14'>
            <div className='w-3/4 mx-auto pt-4 flex flex-col items-start justify-between  md:flex-row  md:items-center gap-5 px-10'>
                <div>
                    <h1 className='text-lg xl:text-xl font-semibold'>Greater Noida Office</h1>
                    <h2 className='mt-3  text-base text-white/40'>Spirinova Technologies Pvt Ltd, <br />iHub Divyasampark business incubator, <br />GNEC-IIT Roorkee, Plot No. 20, Knowledge Park II, <br />Greater Noida, Uttar Pradesh 201310</h2>
                </div>
                <div>
                    <h1 className='text-lg xl:text-xl font-semibold'>Pune Office</h1>
                    <h2 className='mt-2 text-base text-white/40'>Spirinova Technologies Pvt Ltd, <br />Office number 14, First floor, A wing <br />City Vista, Kharadi,  <br />Pune, Maharasthra - 411014‍ </h2>
                </div>
                
            </div>
            <div className='border border-white w-full'></div>
            <div className='w-full flex justify-between items-start gap-4 flex-wrap'>
                <div className='xl:w-1/3 flex flex-col justify-between items-start gap-8'>
                    <div>
                        <h1 className='text-2xl xl:text-4xl font-semibold'>Let’s Build <span className='text-[#1E6AFF]'>Remarkable Products</span> Together!</h1>
                        <h2 className='mt-2 text-sm xl:text-base'>Receive must-read articles and trends on company culture, hiring and work/life balance from leading publications.</h2>
                        <div className='flex mt-8'>
                            <input className='contact-input py-3 px-4 bg-[#282E3C] rounded-r-none' type='email' placeholder='Enter your email' />
                            <button className='contact-input py-3 px-4 bg-[#1E6AFF] rounded-l-none'>
                                <img src={plus} alt="plus" />
                            </button>
                        </div>
                    </div>
                    <div>
                        <img src={logo} alt="logo" />
                    </div>
                </div>
                    <Menu title={"Services"} menu={["Product Consulting","Product Development","IT Consulting","Custom Software Development","Mobile Application Development","Mobile Application Development","UI/UX designs","QA & Audits"]} link="/services/" />
                    <Menu title={"Technologies"} menu={["Blockchain","Data Science","AI & M","AR & VR","Metaverse","IoT (Connected devices)"]} link="/technologies/"/>
                    <Menu title={"Industries"} menu={["FinTech & BFSI","Education Technology","Payments","Retail & Commerce","Supply chain & Logistics","Healthcare","Real Estate","Digital Identity & Digital Records","Gaming"]} link="/industries/"/>
                    <Menu title={"Company"} menu={["About Us","Team ","Blogs"]} link="/company/"/> 
                <div className='flex flex-col gap-4'>
                    <Menu title={"Products"} menu={[""]} link="/products/"/>
                    <Menu title={"Success Stories"} menu={[""]} link="/success-stories/"/>
                    <Menu title={"Blogs"} menu={[""]} link="/blogs/"/>
                    <Menu title={"Contact Us"} menu={[""]} link="#contact"/>
                </div> 
            </div>
            <div className='border border-white w-full'></div>
            <div className='w-full flex justify-between items-start gap-4'>
                <div className='text-sm'>
                    <h2>Do Not Sell or Share My Info</h2>
                    <h2 className='mt-1 text-white/40'>© 2023 Spirinova.dev</h2>
                    {/* <h2 className='mt-2 text-white/30'>Terms of Use&nbsp;&nbsp;&nbsp;Privacy Policy</h2> */}
                    <div className='mt-2 flex gap-3'>
                        <button 
                            className='text-white/30 hover:text-white' 
                            onClick={() => {
                                navigate('/terms-and-conditions');
                                window.scrollTo(0, 0); // Scroll to the top of the page
                            }}
                        >
                            Terms and Conditions
                        </button>
                        <button 
                            className='text-white/30 hover:text-white' 
                            onClick={() => {
                                navigate('/privacy-policy');
                                window.scrollTo(0, 0); // Scroll to the top of the page
                            }}
                        >
                            Privacy Policy
                        </button>
                        <button 
                            className='text-white/30 hover:text-white' 
                            onClick={() => {
                                navigate('/cancellation-and-refund');
                                window.scrollTo(0, 0); // Scroll to the top of the page
                            }}
                        >
                            Cancellation and Refund Policy
                        </button>
                        <button 
                            className='text-white/30 hover:text-white' 
                            onClick={() => {
                                navigate('/shipping-policy');
                                window.scrollTo(0, 0); // Scroll to the top of the page
                            }}
                        >
                            Shipping Policy
                        </button>
                    </div>
                </div>
                <div className='flex items-center gap-4'>
                    <Icon link={"https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/"}><FaFacebookF /></Icon>
                    <Icon><FaTwitter /></Icon>
                    <Icon><FaInstagram/></Icon>
                    <Icon><FaYoutube /></Icon>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Footer