import React, { useEffect } from "react";
import "animate.css";
import WOW from "wowjs";

const ShippingPolicy = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
      animateClass: "animate__animated",
    }).init();
  }, []);

  return (
    <>
      <div className="p-5 xl:px-14 pt-10 xl:pt-16 flex flex-col gap-5">
        <h1 className="wow animate__fadeIn text-3xl xl:text-4xl 2xl:text-6xl font-medium">
            Shipping Policy
        </h1>
        <div className="wow animate__fadeIn lg:mt-3" data-wow-delay="0.5s">
          <p className="desc">At Spirinova, we specialize in providing software services and infrastructure solutions. As our products are digital in nature, the delivery process differs from physical goods. Below is our shipping policy to ensure transparency in how our services are delivered..</p>
          <ol className="list-decimal ml-4 mt-3">
            <li>
                Delivery of Services
                <ul className="list-disc ml-5">
                <li>
                    Code Delivery:
                    <ul className="list-disc ml-5">
                    <li>All software code will be delivered electronically via platforms such as GitHub or other agreed-upon code-sharing platforms.</li>
                    <li>Access to the code repository will be shared with the client as per the agreements.</li>
                    </ul>
                </li>
                <li>
                    Cloud Infrastructure Delivery:
                    <ul className="list-disc ml-5">
                    <li>Infrastructure setups will be provisioned on cloud platforms (e.g., AWS, Azure, Google Cloud) as per the project agreement.</li>
                    <li>Access credentials and deployment details will be shared via email or the client's preferred communication channel or can be provided by the client.</li>
                    </ul>
                </li>
                </ul>
            </li>
            <li>
                Delivery Timelines
                <ul className="list-disc ml-5">
                <li>
                    Digital Delivery:
                    <ul className="list-disc ml-5">
                    <li>Instant access for pre-built software or licenses upon successful payment.</li>
                    <li>For custom software development, delivery timelines will be outlined in the service agreement.</li>
                    </ul>
                </li>
                <li>
                    Cloud Deployments:
                    <ul className="list-disc ml-5">
                    <li>Infrastructure deployment timelines are subject to the complexity of the project and will be discussed and agreed upon with the client beforehand.</li>
                    </ul>
                </li>
                </ul>
            </li>
            <li>
                Access Credentials
                <ul className="list-disc ml-5">
                <li>Login credentials for cloud infrastructure or software platforms will be securely emailed to the client at the address provided during the registration or purchase process or can be provided by client.</li>
                <li>If you do not receive the credentials within the agreed timeframe, please contact us immediately at umesh@spirinova.dev.</li>
                </ul>
            </li>
            <li>
                International Delivery
                <ul className="list-disc ml-5">
                <li>Our software services are available globally unless restricted by local laws or regulations.</li>
                <li>Clients are responsible for ensuring compliance with local software usage laws.</li>
                </ul>
            </li>
            <li>
                Lost or Delayed Deliveries
                <ul className="list-disc ml-5">
                <li>
                    Digital Products:
                    <ul className="list-disc ml-5">
                    <li>If you do not receive access to your purchased service within the specified timeframe, contact us at umesh@spirinova.dev.</li>
                    </ul>
                </li>
                <li>
                    Cloud Infrastructure:
                    <ul className="list-disc ml-5">
                    <li>Any delays due to unforeseen technical issues will be communicated promptly, and we will work with you to ensure timely resolution.</li>
                    </ul>
                </li>
                </ul>
            </li>
            <li>
                Refunds and Cancellations
                <p>For cancellations or refunds, please refer to our Cancellation and Refund Policy.</p>
            </li>
            <li>
                Security and Confidentiality
                <ul className="list-disc ml-5">
                <li>All access credentials and delivery details are shared securely to ensure data protection.</li>
                <li>We encourage clients to immediately change passwords after receiving access credentials for security purposes.</li>
                </ul>
            </li>
            <li>
                Changes to the Shipping Policy
                <ul className="list-disc ml-5">
                <li>We reserve the right to update this policy as needed to reflect changes in our services or business operations. Any changes will be communicated via our website.</li>
                </ul>
            </li>
          </ol>
          <div className="mt-3">
            <h2 className="font-semibold">Contact Information</h2>
            <p>If you have any questions, please contact us:</p>
            <ul className="list-disc ml-9">
                <li>Email: umesh@spirinova.dev</li>
                <li>Phone: +91-8605633693</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingPolicy;